import '../styles/main.scss';
var $ = require( "jquery" );

(function (window) {

  'use strict';
var scrollingValue;
var timelineComponentElement = $('.new-design-timeline__timeline-component');

$('.new-design-timeline__bar-menu__bar').on('input', function (event) {
  scrollingValue = $(this).val();
  var timelineComponent = timelineComponentElement[0];
  var maxScrollLeftWidth = timelineComponent.scrollWidth - timelineComponent.clientWidth;
  timelineComponentElement.scrollLeft(maxScrollLeftWidth / 100 * scrollingValue);
});

  timelineComponentElement.scroll(function(event) {
  var maxScrollLeftWidth = event.target.scrollWidth - event.target.clientWidth;
  $('.new-design-timeline__bar-menu__bar').val(event.target.scrollLeft / maxScrollLeftWidth * 100)
});


$('#desktop-our-mission').click(function () {
  $('.new-design-our-mission')[0].scrollIntoView({behavior: 'smooth'});
})

$('#desktop-our-life').click(function () {
  $('.our-mission-driven-team')[0].scrollIntoView({behavior: 'smooth'});
})

$('#desktop-our-partnership').click(function () {
  $('.our-uk-partnership')[0].scrollIntoView({behavior: 'smooth'});
})

$('#desktop-our-history').click(function () {
  $('.new-design-our-story')[0].scrollIntoView({behavior: 'smooth'});
})



$('#mobile-our-mission').click(function () {
  $('.new-design-our-mission')[0].scrollIntoView({behavior: 'smooth'});
})

$('#mobile-our-life').click(function () {
  $('.our-mission-driven-team')[0].scrollIntoView({behavior: 'smooth'});
})

$('#mobile-our-partnership').click(function () {
  $('.our-uk-partnership')[0].scrollIntoView({behavior: 'smooth'});
})

$('#mobile-our-history').click(function () {
  $('.new-design-our-story')[0].scrollIntoView({behavior: 'smooth'});
})



$('.mobile-menu-icon, .close').click(function () {
  $('.mobile-nav').toggleClass('open');
  // $('.std').toggleClass('open-mobile-navbar-dropdown');
});

$('.close').keypress(function () {
  $('.mobile-nav').toggleClass('open');
  // $('.std').toggleClass('open-mobile-navbar-dropdown');
});



}(window));
